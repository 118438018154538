<template>
  <v-container class="pt-120">
    <h1>Terms of Service</h1>
    <h3>
      PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
      USING THIS WEBSITE.
    </h3>
    <p>
      All users of this site agree that access to and use of this site is
      subject to the following terms and conditions and other applicable law. If
      you do not agree to these terms and conditions, please do not use this
      site or app.
    </p>
    <h3>Disclaimer</h3>
    <p>
      You agree that your use of our Website or Services is solely at your own
      risk. You agree that such Service is provided on an "as is" and "as
      available" basis. We expressly disclaim all warranties of any kind,
      whether express or implied, including but not limited to the implied
      warranties of merchantability, fitness for a particular purpose and
      non-infringement. We make no warranty that the Services will meet your
      requirements, or that the Service will be uninterrupted, timely, secure,
      or error-free; nor do we make any warranty as to the results that may be
      obtained from the use of the Service or as to the accuracy or reliability
      of any information obtained through the Service or that defects in the
      Service will be corrected. No advice or information, whether oral or
      written, obtained by you from us or through the Service shall create any
      warranty not expressly made herein.
    </p>
    <p>
      RaveVIP.com <strong>is a family friendly site</strong> and we DO NOT
      intentionally accept or allow the following types of uses into our
      program: Gambling, Adult content (porn, soft porn, adult solicitation,
      sexual speech in any form), Threats, Trolling, Defamation, Acts designed
      to elicit negative reactions from Artist/Talent, Pharmacy (Cheap drugs,
      Viagra, male/female enhancement, etc.), Hate, Link Farms or Spam Sites,
      Politics, and any other use we reasonably deem offensive or disagreeable
      to the Artist/Talent. If you do, we will cancel your membership without
      hesitation and without refund, and we reserve the right to pursue any and
      all legal means to be made whole. We do not need to explain our decision
      or reasons if we reject or cancel any membership. All conversations with
      Artists/Talent are recorded, and by using this service, you consent to the
      unfettered use of the recordings,<i
        >including, among other things, to pursue our legal remedies should you
        violate any conditions of use.</i
      >
    </p>
    <p>
      RaveVIP.com and Rave Now LLC retains all rights to any video or audio
      recordings of any form, and photographs. This Agreement does not transfer
      to you any intellectual property owned by Rave Now LLC or third-parties,
      and all rights, titles, and interests in and to such property will remain
      (as between the parties) solely with Rave Now LLC. All intellectual
      property including but not limited to trademarks, service marks, graphics,
      recordings in any form, photographs, and logos used in connection with our
      Website or Services, are trademarks or registered trademarks of Rave Now
      LLC or Rave Now LLC licensors. Other trademarks, service marks, graphics
      and logos used in connection with our Website or Services may be the
      trademarks of other third-parties. Your use of our Website and Services
      grants you no right or license to reproduce or otherwise use any Rave Now
      LLC or third-party trademarks.
    </p>
    <p>
      Any license for use of material that may be granted by Rave Now LLC is
      specifically limited to personal use only and such material may not be
      copied or duplicated in any form without prior written permission signed
      by an officer of Rave Now LLC. Rave Now LLC retains its right to revoke
      any license to use. Artist/Talent may act as RaveVIP.com and Rave Now
      LLC’s representative in initially deciding whether, if at all, to grant
      license for use.
    </p>
    <h3>Billing</h3>
    <p>
      It is RaveVIP.com’s policy to charge you the full transaction cost per the
      fee schedule as posted on each individual artist/talent’s RaveVIP.com
      listing.
    </p>
    <p>
      RaveVIP.com uses Stripe.com as its payment portal. Stripe.com is not
      operated by or associated with us, and your use of stripe.com is subject
      to their terms. We reserve the right to change payment portals at any
      time. We are not responsible for any delays or failures caused by a third
      party payment provider.
    </p>
    <p>
      RaveVIP.com agrees that no credit card information will be stored on file,
      except under Stripe.com’s PCI-compliant policies (which may include
      retaining cc info under personal sign-in accounts).
    </p>
    <p>
      For your convenience, the following is a link to Stripe.com’s privacy
      policy:
      <a target="_blank" href="https://stripe.com/us/privacy"
        >https://stripe.com/us/privacy</a
      >.
    </p>
    <h3>Refunds &amp; Guarantees</h3>
    <p>
      RaveVIP.com offers the following guarantee: You will not be charged until
      you purchase a RaveVIP.com product/service, and for some reason you decide
      that you would like a refund, you have 7 days to request a refund. If you
      request a refund within 7 days from the date of purchase, RaveVIP.com will
      give you a refund of up to one-half of your purchase price for the
      product/service. If you do not request a refund within the 7 day refund
      period, you forfeit this option and will not be eligible for a refund.
      Repeated requests for refunds from any customer may result in the banning
      of future bookings and/or other use of the services of RaveVIP.com and its
      artists/talent, in RaveVIP.com’s sole discretion.
    </p>
    <p>
      There is no obligation on the part of any artist/talent to schedule or
      fulfil a scheduled booking. Should this occur, RaveVIP.com shall refund
      your full purchase price within 7 days of cancellation. You agree that
      RaveVIP.com nor its artists/talent are liable for any other remuneration
      or compensation for such cancellation.
    </p>
    <p>
      We do not offer refunds on any additional services that you may purchase
      in the members area once you are a member.
    </p>
    <h3>Email Opt-in Policy</h3>
    <p>
      When using our RaveVIP.com service you will be opted-in to receive weekly
      email updates, tips and suggestions we believe will help build, grow and
      enhance your site. You may unsubscribe at any time by clicking on the
      "Unsubscribe or Modify my subscription" link at the bottom of any email
      sent.
    </p>
    <h3>Copyright</h3>
    <p>
      The entire content included in this site, including but not limited to
      text, graphics or code is copyrighted as a collective work under the
      United States and other international copyright laws, and is the property
      of RaveVIP.com. The collective work includes works that are licensed to
      RaveVIP.com. Copyright 2019, RaveVIP.com ALL RIGHTS RESERVED. Permission
      is granted to electronically copy and print hard copy portions of this
      site for the sole purpose of placing an order with RaveVIP.com or
      purchasing our products. Any other use, including but not limited to the
      reproduction, distribution, display or transmission of the content of this
      site is strictly prohibited, unless authorized by RaveVIP.com. You further
      agree not to change or delete any proprietary notices from materials
      downloaded from the site.
    </p>
    <h3>Trademarks</h3>
    <p>
      All trademarks, service marks and trade names of RaveVIP.com used in the
      site are trademarks or registered trademarks of RaveVIP.com.
    </p>
    <h3>Warranty Disclaimer</h3>
    <p>
      This site and the materials and products on this site are provided "as is"
      and without warranties of any kind, whether express or implied. To the
      fullest extent permissible pursuant to applicable law, RaveVIP.com,
      including the website’s owner Rave Now LLC, disclaims all warranties,
      express or implied, including, but not limited to, implied warranties of
      merchantability and fitness for a particular purpose and non-infringement.
      RaveVIP.com does not represent or warrant that the functions contained in
      the site will be uninterrupted or error-free, that the defects will be
      corrected, r that this site or the server that makes the site available
      are free of viruses or other harmful components. RaveVIP.com does not make
      any warranties or representations regarding the use of the materials in
      this site in terms of their correctness, accuracy, adequacy, usefulness,
      timeliness, reliability or otherwise. Some states do not permit
      limitations or exclusions on warranties, so the above limitations may not
      apply to you.
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      RaveVIP.com and its owner Rave Now, LLC shall not be liable for any
      special or consequential damages that result from the use of, or the
      inability to use, the services and products offered on this site, or the
      performance of the services and products.
    </p>
    <h3>Typographical Errors</h3>
    <p>
      In the event that a RaveVIP.com product is mistakenly listed at an
      incorrect price, RaveVIP.com reserves the right to refuse or cancel any
      orders placed for product listed at the incorrect price. RaveVIP.com
      reserves the right to refuse or cancel any such orders whether or not the
      order has been confirmed and your credit card charged. If your credit card
      has already been charged for the purchase and your order is cancelled,
      RaveVIP.com will issue a credit to your credit card account in the amount
      of the incorrect price.
    </p>
    <h3>Term; Termination</h3>
    <p>
      These terms and conditions are applicable to you upon your accessing the
      site and/or completing the registration or shopping process. These terms
      and conditions, or any part of them, may be terminated by RaveVIP.com
      without notice at any time, for any reason. The provisions relating to
      Copyrights, Trademark, Disclaimer, Limitation of Liability,
      Indemnification and Miscellaneous, shall survive any termination.
    </p>
    <h3>Use of Site</h3>
    <p>
      Harassment in any manner or form on the site, including via e-mail, chat,
      or by use of obscene or abusive language, is strictly forbidden.
      Impersonation of others, including a RaveVIP.com or other licensed
      employee, host, or representative, as well as other members or visitors on
      the site is prohibited. You may not upload to, distribute, or otherwise
      publish through the site any content which is libelous, defamatory,
      obscene, threatening, invasive of privacy or publicity rights, abusive,
      illegal, or otherwise objectionable which may constitute or encourage a
      criminal offense, violate the rights of any party or which may otherwise
      give rise to liability or violate any law. You may not upload commercial
      content on the site or use the site to solicit others to join or become
      members of any other commercial online service or other organization.
    </p>
    <h3>Participation Disclaimer</h3>
    <p>
      RaveVIP.com does not and cannot review all communications and materials
      posted to or created by users accessing the site, and are not in any
      manner responsible for the content of these communications and materials.
      You acknowledge that by providing you with the limited ability to view and
      distribute user-generated content on the site, RaveVIP.com is merely
      acting as a passive conduit for such distribution and is not undertaking
      any obligation or liability relating to any contents or activities on the
      site. However, RaveVIP.com reserves the right to block or remove
      communications or materials that it determines to be (a) abusive,
      defamatory, or obscene, (b) fraudulent, deceptive, or misleading, (c) in
      violation of a copyright, trademark or; other intellectual property right
      of another or (d) offensive or otherwise unacceptable to RaveVIP.com in
      its sole discretion.
    </p>
    <h3>Indemnification</h3>
    <p>
      You agree to indemnify, defend, and hold harmless Rave Now LLC, its
      associated entities, its officers, directors, employees, agents, licensors
      and suppliers (collectively the "Service Providers") from and against all
      losses, expenses, damages and costs, including reasonable attorneys' fees,
      resulting from any violation of these terms and conditions or any activity
      related to your account (including negligent or wrongful conduct) by you
      or any other person accessing the site using your Internet account.
    </p>
    <h3>Third-Party Links</h3>
    <p>
      In an attempt to provide increased value to our visitors, RaveVIP.com may
      link to sites operated by third parties. However, even if the third party
      is affiliated with RaveVIP.com, RaveVIP.com has no control over these
      linked sites, all of which have separate privacy and data collection
      practices, independent of RaveVIP.com. These linked sites are only for
      your convenience and therefore you access them at your own risk.
      Nonetheless, RaveVIP.com seeks to protect the integrity of its website and
      the links placed upon it and therefore requests any feedback on not only
      its own site, but for sites it links to as well (including if a specific
      link does not work).
    </p>
    <h3>Contacting Us</h3>
    <p>
      If there are any questions regarding these terms you may contact us.
    </p>
  </v-container>
</template>
<script>
export default {
  name: 'TermsOfService',
  beforeMount() {
    this.$store.commit('signupModal', false)
  },
}
</script>
